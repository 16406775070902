import { motion } from 'framer-motion';
import Button from '@ui/atoms/design-system/Button';
import Image from '@atoms/Image';

const ModalWithButton = ({
    show,
    onClose,
    label,
    width,
    height,
    hideTitle,
    children,
    labelSize,
    id,
    labelPadding,
    animation,
    mainWrapperClassName = '',
    subLabel = '',
    subLabelClass = '',
    labelClassName = '',
    paddingClass = 'p-6',
    buttonAction = () => {},
    buttonText = null,
    buttonLoading = false,
    ...property
}) => {
    const animations = {
        popIn: {
            hidden: {
                opacity: 0,
            },
            visible: {
                opacity: [1, 1, 1, 1, 1],
                scale: [0, 0.25, 0.5, 0.75, 1, 1.01, 1],
                transition: {
                    duration: 0.3,
                    type: 'spring',
                    damping: 500,
                    stiffness: 700,
                },
            },
            exit: {
                opacity: 0,
                scale: [1, 0.5, 0],
                transition: {
                    duration: 0.1,
                    type: 'spring',
                    damping: 50,
                    stiffness: 700,
                },
            },
        },
        dropIn: {
            hidden: {
                x: '-100vw',
                opacity: 0,
            },
            visible: {
                x: '0',
                opacity: 1,
                transition: {
                    duration: 0.1,
                    type: 'spring',
                    damping: 50,
                    stiffness: 700,
                },
            },
            exit: {
                x: '-100vw',
                opacity: 0,
                transition: {
                    duration: 0.3,
                    type: 'spring',
                    damping: 50,
                    stiffness: 700,
                },
            },
        },
        dropInY: {
            hidden: {
                y: '-100vh',
                opacity: 0,
            },
            visible: {
                y: '0',
                opacity: 1,
                transition: {
                    duration: 0.1,
                    type: 'spring',
                    damping: 50,
                    stiffness: 700,
                },
            },
            exit: {
                y: '200vh',
                opacity: 0,
                transition: {
                    duration: 0.1,
                    type: 'spring',
                    damping: 50,
                    stiffness: 1000,
                },
            },
        },
        newspaper: {
            hidden: {
                transform: 'scale(0) rotate(720deg)',
                opacity: 0,
                transition: {
                    delay: 0.3,
                },
            },
            visible: {
                transform: ' scale(1) rotate(0deg)',
                opacity: 1,
                transition: {
                    duration: 0.5,
                },
            },
            exit: {
                transform: 'scale(0) rotate(-720deg)',
                opacity: 0,
                transition: {
                    duration: 0.3,
                },
            },
        },
        flip: {
            hidden: {
                transform: 'scale(0) rotateX(-360deg)',
                opacity: 0,
                transition: {
                    delay: 0.3,
                },
            },
            visible: {
                transform: ' scale(1) rotateX(0deg)',
                opacity: 1,
                transition: {
                    duration: 0.5,
                },
            },
            exit: {
                transform: 'scale(0) rotateX(360deg)',
                opacity: 0,
                transition: {
                    duration: 0.5,
                },
            },
        },
        opacity: {
            hidden: {
                opacity: 0,
            },
            visible: {
                opacity: 1,
                transition: {
                    duration: 0.1,
                },
            },
            hidden: {
                opacity: 0,
                transition: {
                    duration: 0.1,
                },
            },
        },
    };

    const yScroll = height ? `overflow-y-auto ${height}` : 'overflow-y-auto';
    const labelFontSize = labelSize ? labelSize : 'text-xl md:text-2xl';

    return (
        <div
            className={`fixed top-0 left-0  items-center justify-center w-full h-full modal  z-100  p-4 ${!show ? 'hidden' : 'flex !mt-0'
                } ${mainWrapperClassName}`}
        >
            <motion.div
                variants={animations.opacity}
                initial="hidden"
                animate="visible"
                exit="exit"
                className="absolute z-40 w-full h-full bg-gray-900 !opacity-50 modal-overlay"
            ></motion.div>

            <motion.div
                variants={animation && animations[animation]}
                initial="hidden"
                animate="visible"
                exit="exit"
                className={`text-basicBlack bg-basicWhite m-2 md:mx-0 ${width}  rounded-lg shadow-lg z-50 ${height} flex flex-col`}
            >
                {!hideTitle && (
                    <div
                        className={`flex items-center justify-between border-b ${
                            labelPadding ? labelPadding : 'p-5'
                        } `}
                    >
                        <div className="flex flex-col gap-1">
                            <div className="flex items-center gap-2.5">
                                <p
                                    className={`font-semibold font-bahnschrift text-gray-800  ${labelFontSize} ${labelClassName}`}
                                >
                                    {label}
                                </p>
                                {buttonText ? <div className='hidden md:flex border-l border-gray-300 border-h-5'>
                                    <Button 
                                        buttonText={buttonText}
                                        onClick={buttonAction}
                                        isLoading={buttonLoading}
                                        buttonType='borderless'
                                        prefixComponent={
                                            <Image
                                                src="/images/download-new.svg"
                                                width="16"
                                                height="16"
                                            />
                                        }
                                        buttonSize = "small"
                                        className = '!py-0 !px-2.5 underline'
                                    />
                                </div> : null}
                            </div>
                            {subLabel ? (
                                <p
                                    className={`p4-regular text-gray-600  ${subLabelClass}`}
                                >
                                    {subLabel}
                                </p>
                            ) : null}
                        </div>
                        <div className="z-50 cursor-pointer modal-close">
                            <button onClick={onClose} id={id} type='button'>
                                <Image
                                    src="/images/close.svg"
                                    width="20"
                                    height="20"
                                />
                            </button>
                        </div>
                    </div>
                )}
                <div
                    className={`${paddingClass} ${property.className} ${yScroll}`}
                >
                    {children}
                </div>
            </motion.div>
        </div>
    );
};

export default ModalWithButton;

import React, { useState } from 'react';
import { Text, Image } from '@atoms/index';
import { getNumberWithComma } from '@helpers/miscelleanous';
import PaymentScheduleDesktopView from './PaymentScheduleDesktopView';
import PaymentScheduleMobileView from './PaymentScheduleMobileView';
import {
    getTotalReturnReceived,
    payScheduleDateWithInterestObj,
    showDetailsStatusArrow,
    showDetailsStatusMessage,
    totalAmount,
} from './lib/helper';
import texts from './en.json';
import Button from '@ui/atoms/design-system/Button';
import ModalWithButton from '@molecules/ModalWithButton';
import SinglePaymentScheduleView from '@components/Investor/Investment/PaymentSchedule/SinglePaymentScheduleView';

const PaymentSchedule = ({
    show,
    onClose,
    returns,
    paySchedule,
    units,
    unitPrice,
    viewInvestments = false,
    id,
    downloadUpcomingPaymentSchedule = () => {},
    buttonLoading = false,
    isSinglePaymentSchedule = false
}) => {
    const [selectedPayment, setSelectedPayment] = useState({});
    const [showDetails, setShowDetails] = useState(false);

    return (
        <ModalWithButton
            show={show}
            onClose={onClose}
            width={`w-full ${isSinglePaymentSchedule ? 'md:w-[504px]' : 'md:w-2/3'} md:max-w-screen-md max-w-[99%] !m-0`}
            height="max-h-122 !p-0"
            label={texts.RepaymentScheduleLabel}
            className="thin-scrollbar"
            id={id}
            buttonAction = {() => downloadUpcomingPaymentSchedule(units)}
            buttonText = {units ? texts.Download : null}
            buttonLoading = {buttonLoading}
            children={
                <>
                    <div className="grid grid-cols-2 m-4 gap-2 md:grid-rows-1 md:grid-cols-2">
                        <div className="p-2 md:px-3 border rounded-lg border-gray-200 2xl:px-6">
                        <div className="flex flex-col md:flex-row w-full">
                                <Image
                                    src={texts.CubeBoxImagePath}
                                    className="w-4 md:w-8 pb-[6px] md:pb-0"
                                />
                                <div className="flex w-full flex-col justify-start md:pl-3 2xl:pl-5">
                                    <Text
                                        content={texts.NumberOfUnits}
                                        className="text-gray-500 p6-regular md:p4-regular"
                                    />
                                    <Text
                                        content={`${units}`}
                                        className="text-basicBlack p5-semibold md:p4-semibold"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="p-2 md:px-3 border rounded-lg border-gray-200 2xl:px-6">
                        <div className="flex flex-col md:flex-row w-full">
                                <Image
                                    src={texts.WalletImagePath}
                                    className="w-4 md:w-8 pb-[6px] md:pb-0"
                                />
                                <div className="flex w-full flex-col justify-start md:pl-3 2xl:pl-5">
                                    <Text
                                        content={texts.InvestedAmount}
                                        className="text-gray-500 p6-regular md:p4-regular"
                                    />
                                    <Text
                                        content={`₹ ${getNumberWithComma(
                                            Number(unitPrice)
                                        )}`}
                                        className="text-basicBlack p5-semibold md:p4-semibold"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='pl-4 pr-4 pb-4 w-full md:hidden'>
                        <Button 
                           buttonText = {texts.DownloadSchedule}
                           buttonType = 'secondary'
                           buttonSize = 'extrasmall'
                           prefixComponent = {
                            <Image
                                src="/images/download-new.svg"
                                className="w-4 h-4"
                            />
                           }
                           isLoading = {buttonLoading}
                           onClick = {() => downloadUpcomingPaymentSchedule(units)}
                           widthClass='w-full'
                        />
                    </div>
                    {isSinglePaymentSchedule ? 
                    <SinglePaymentScheduleView 
                        paySchedule={paySchedule}
                        returns={returns}
                        units={units}
                    /> 
                    :
                    (/* repayment schedule table for desktop view  */
                    <>
                    <PaymentScheduleDesktopView
                        viewInvestments={viewInvestments}
                        totalAmount={totalAmount(returns)}
                        paySchedule={paySchedule}
                        returns={returns}
                        payScheduleDateWithInterestObj={
                            payScheduleDateWithInterestObj
                        }
                        units={units}
                        selectedPayment={selectedPayment}
                        setSelectedPayment={setSelectedPayment}
                        getTotalReturnReceived={getTotalReturnReceived}
                        setShowDetails={setShowDetails}
                        showDetails={showDetails}
                        showDetailsStatusMessage={showDetailsStatusMessage}
                        showDetailsStatusArrow={showDetailsStatusArrow}
                    />

                    { /* repayment schedule table for mobile view  */}
                    <PaymentScheduleMobileView
                        viewInvestments={viewInvestments}
                        paySchedule={paySchedule}
                        returns={returns}
                        payScheduleDateWithInterestObj={
                            payScheduleDateWithInterestObj
                        }
                        units={units}
                        totalAmount={totalAmount(returns)}
                        selectedPayment={selectedPayment}
                        setSelectedPayment={setSelectedPayment}
                        getTotalReturnReceived={getTotalReturnReceived}
                        setShowDetails={setShowDetails}
                        showDetails={showDetails}
                        showDetailsStatusMessage={showDetailsStatusMessage}
                        showDetailsStatusArrow={showDetailsStatusArrow}
                    />
                    </>)}
                </>
            }
        />
    );
};

export default PaymentSchedule;

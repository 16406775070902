import React from 'react';
import { Text } from '@atoms/index';
import { getNumberAsCurrencyWithTwoFractionDigits } from '@helpers/miscelleanous';
import {  totalAmount } from './lib/helper';
import texts from './en.json';

const SinglePaymentScheduleView = ({
    returns,
    paySchedule,
    units,
}) => {
    const addLoader = (condition, content) => {
        return (
            <>
                {condition != undefined ? content : <BouncingDotsLoaderForNumbers className={'w-1.5 h-1.5 mx-0.5'} />}
            </>
        )
    }
    const firstPaymentSchedule = paySchedule[0];
    return (
        <>
            <div className="flex flex-col items-center self-stretch justify-center gap-2 px-4 pb-4 w-full">
                <div className="flex flex-col items-center self-stretch justify-center gap-3">
                    <div className="flex flex-col items-center justify-start w-full gap-2 pb-3 border-primary-50 md:pb-4 border-b-1">
                        <div className="inline-flex flex-col items-center self-stretch justify-start w-full gap-3">
                            <div className="flex justify-between w-full">
                                <div className="flex items-center justify-center">

                                    <Text
                                        className="text-gray-500 p5-medium"
                                        content={texts.Date}
                                    />
                                </div>
                                {addLoader(firstPaymentSchedule?.formattedDate, (<Text
                                    className="text-right text-gray-600 p5-medium"
                                    content={firstPaymentSchedule?.formattedDate}
                                />))}
                            </div>
                            <div className="flex self-stretch justify-between">
                                <div className="flex items-center justify-center">
                                    <Text
                                        className="text-gray-500 p5-medium"
                                        content={texts.Principal}
                                    />
                                </div>
                                {addLoader(firstPaymentSchedule?.totalPrincipal, <Text
                                    className="text-right text-gray-600 p5-medium"
                                    content={
                                        getNumberAsCurrencyWithTwoFractionDigits(
                                            Number(firstPaymentSchedule.totalPrincipal))
                                    }
                                />)}
                            </div>
                            <div className="inline-flex items-center self-stretch justify-between gap-1">
                                    <div className="flex items-center justify-center">
                                        <Text
                                            className="text-gray-500 p5-medium"
                                            content={texts.RedemptionPremium}
                                        />
                                    </div>
                                    {addLoader(firstPaymentSchedule?.totalInterest, <Text
                                        className="text-right text-gray-600 p5-medium"
                                        content={getNumberAsCurrencyWithTwoFractionDigits(
                                            Number(firstPaymentSchedule?.totalInterest)
                                        )}
                                    />)}
                                </div>
                            
                        </div>
                    </div>
                    <div className="inline-flex items-center self-stretch justify-between gap-1">
                        <div className="flex items-center justify-center">
                            <Text
                                className="text-gray-500 p4-medium"
                                content={texts.RedemptionValue}
                            />
                        </div>
                        {addLoader(returns, <Text
                            className="text-right text-gray-900 p4-bold"
                            content={`${getNumberAsCurrencyWithTwoFractionDigits(
                                Number(totalAmount(returns) * Number(units))
                            )}`}
                        />)}
                    </div>
                </div>
            </div>
        </>
    );
};

export default SinglePaymentScheduleView;

import React, { useMemo, useRef, useState } from 'react';
import AvatarSection from './AvatarSection';
import ExpandNavigationItem from './ExpandNavigationItem';
import RmElement from '@molecules/BottomNavigation/RmElement';
import MenuItemsWithAuth from './MenuItemsWithAuth';
import { Image, Text } from '@ui/atoms';
import useOutsideClick from '@hooks/useOutsideClick';
import texts from "./en.json";
import { triggerCustomGaEvent } from '@helpers/miscelleanous';
import { navigationGaEventsName } from '@helpers/ga-events-name-enum';
import { navbarMenuTitles } from '@helpers/enums/navbar-menu-titles';

const NavigationPanel = ({
    menu,
    menuOpen,
    height,
    rmDetails,
    avatar,
    name,
    isStaticPage,
    logout,
    handleMenu,
    kycStatus,
    showRMDetails,
    showNudgeForNewStepsAdded
}) => {
    const navigationRef = useRef();
    const [showChildren, setShowChildren] = useState(rmDetails ? 'Relationship Manager' : '');
    useOutsideClick(navigationRef, () => menuOpen && handleMenu());

    const filterMenu = (menuItems, isStaticPage) => {

         const excludeNames = [
            navbarMenuTitles.Dashboard,
            navbarMenuTitles.Opportunities,
            navbarMenuTitles.BecomeAPartner,
            navbarMenuTitles.BondDirectory,
        ];
    
        const referAndEarn = menuItems.filter(item => item?.name === navbarMenuTitles.ReferAndEarn && item?.showWhenLoggedIn);
        const filteredItems = menuItems.filter(item => !excludeNames.includes(item.name) && item?.showWhenLoggedIn && item?.name !== navbarMenuTitles.ReferAndEarn);
    
        if (isStaticPage) {
            return [...referAndEarn, ...filteredItems];
        } else {
            return referAndEarn;
        }
    };

    const menuItems = useMemo(() => filterMenu(menu, isStaticPage), [menu, isStaticPage]);

    return (
        <div
            className={`${
                menuOpen ? 'mobile-menu-visible block' : 'mobile-menu-hidden hidden'
            } fixed top-16 md:top-16 right-0 z-100 mobile-menu min-width-full w-full items-end bg-basicBlack/40`}
        >
            <div
                className="navigation-panel relative flex flex-col justify-between gap-4 max-w-[400px]  w-[80%] pb-16 bg-basicWhite ml-auto mobile-menu-content px-3 overflow-auto scroll-vertical-width-0 animate__slideInRight animate__animated"
                style={{ height }}
                ref={navigationRef}
            >
                <div className="w-full flex flex-col gap-4 pt-3">
                    <AvatarSection avatar={avatar} name={name} kycStatus={kycStatus} showNudgeForNewStepsAdded={showNudgeForNewStepsAdded} />
                    <div>
                        {rmDetails && showRMDetails && (
                            <ExpandNavigationItem
                                icon='/images/header/relationship-manager-icon.svg'
                                field={{ name: 'Relationship Manager', class: 'p4-medium text-gray-500' }}
                                className="flex items-center p-2 rounded-xl flex-col"
                                showChildren={showChildren}
                                setShowChildren={setShowChildren}
                                gTag={navigationGaEventsName.CLICKED_RM_SIDENAV_MOBILE_WEB_NAVIGATION}
                            >
                                <RmElement
                                    rmDetails={rmDetails}
                                    className="pt-3 border-t border-gray-200 mt-3 flex flex-col gap-3 w-full px-1 pb-1"
                                    buttonType="primary"
                                    gTag={navigationGaEventsName.CLICKED_CHAT_WITH_RM_SIDENAV_MOBILE_WEB_NAVIGATION}
                                />
                            </ExpandNavigationItem>
                        )}
                        <MenuItemsWithAuth 
                            menuItems={menuItems} 
                            showChildren={showChildren}
                            setShowChildren={setShowChildren}
                            handleMenu={handleMenu}
                        />
                    </div>
                </div>
                <div className="flex items-center gap-3 mt-6 mb-4 cursor-pointer px-3" onClick={() => {
                    logout();
                    handleMenu();
                    triggerCustomGaEvent(navigationGaEventsName.CLICKED_LOGOUT_SIDENAV_MOBILE_WEB_NAVIGATION)
                }}>
                    <Image src={texts?.LogoutIcon} alt="logout" className="w-10 h-10" />
                    <Text content={texts?.Logout} className="p4-medium text-gray-500" />
                </div>
            </div>
        </div>
    );
};

export default NavigationPanel;